import React from 'react';
import { Layout } from '../components/shared/Layout';

const BewonersPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>De Haagse Munt voor particulieren</h1>
            <p>
              De Haagse Munt is er in de eerste plaats voor de bewoners van Den
              Haag. We zijn net gestart met de Munt. Op dit moment is meedoen
              met De Haagse Munt alleen nog op uitnodiging. Interesse om mee te
              doen?
            </p>
            <p>
              Na de eerste testperiode (die nu loopt) is meedoen eenvoudig en
              werkt als volgt:
            </p>
            <ul>
              <li>
                Je rekening openen Kies de rekeningsoort en vul je gegevens in.
                Je krijgt een verificatiemail op het mailadres dat je opgaf.
                Klik op de link daarin en je rekening doet het! In een tweede
                mail krijg je je rekeninggegevens.
              </li>
              <li>
                Legitimeren is jouw keuze Legitimeren is bij De Haagse Munt een
                keuze. Doe je het niet, dan weten anderen niet precies wie jij
                bent. Je hebt dan wat minder rechten. Door je te legitimeren kun
                je volledig gebruik maken van de faciliteiten van De Haagse
                Munt.
              </li>
              <li>
                Je profiel invullen Ga op dehaagsemunt.nl naar &amp;Mijn
                Munt&amp; en dan naar &amp;Profiel&amp;. Vul de informatie in
                die je wilt delen en hoe je op de hoogte gehouden wilt worden.
              </li>
              <li>
                Adverteren Heb je als bewoner iets in de aanbieding in ruil voor
                Haagse Munten? Ga adverteren! Je mag meteen zoveel advertenties
                plaatsen als je wilt. Gratis.
              </li>
              <li>
                Lokaal inkopen! Kies je Haagse Munt omdat je fan bent van lokale
                handel? Dat zien we graag. Met je Haagse Munt-rekening koop je
                lokaal in en betaal je met Haags geld.
              </li>
              <li>
                Betalen Met een gevalideerde Haagse Munt rekening kun je saldo
                kopen. Betalen vanaf je desktop of laptop doe je online, vanaf
                je smartphone met onze web-app.
              </li>
            </ul>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BewonersPage;
